import { EIso3166OneAlpha2German, EIso3166OneAlpha2, EIso3166OneAlpha2English, EIso3166OneAlpha2CountryGroup, EIso3166OneAlpha2EeaCountries, EIso3166OneAlpha2EuCountries, EIso3166OneAlpha2AdequacyCountries, EIso3166OneAlpha2AWSCountries, EIso3166OneAlpha2CloudflareCountries, EIso3166OneAlpha2FastlyCountries, EIso3166OneAlpha2GoogleCloudCountries, EIso3166OneAlpha2AutomatticCountries } from "../types";
/**
 * Get key-value pares as object of countries available in ISO 3166-1 alpha2.
 *
 * @param language Language of values
 * @return key-value object with all countries
 */ function iso3166OneAlpha2KeyValue() {
    let language = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "en";
    const defaultTranslations = Object.entries(EIso3166OneAlpha2English);
    const countryCodes = Object.values(EIso3166OneAlpha2);
    let translations;
    const result = {};
    switch(language){
        case "de":
        case "de_formal":
        case "de_informal":
            translations = Object.entries(EIso3166OneAlpha2German);
            break;
        default:
            translations = defaultTranslations;
            break;
    }
    // Fill country codes in result object with translations, and if there is no translation, take it from the default language (en)
    for (const key of countryCodes){
        var _translations_find, _defaultTranslations_find;
        const findByKey = (param)=>{
            let [translationKey] = param;
            return translationKey === key;
        };
        result[key] = ((_translations_find = translations.find(findByKey)) === null || _translations_find === void 0 ? void 0 : _translations_find[1]) || ((_defaultTranslations_find = defaultTranslations.find(findByKey)) === null || _defaultTranslations_find === void 0 ? void 0 : _defaultTranslations_find[1]);
    }
    return result;
}
function resolveDataProcessingCountries(countries) {
    let resolveDataProcessingInCountries = [];
    if (countries) {
        const countryGroups = [];
        const filteredDataProcessingInCountries = countries.filter((entry)=>{
            if (entry in EIso3166OneAlpha2CountryGroup) {
                countryGroups.push(entry);
                return false;
            }
            return true;
        });
        const resolvedCountries = countryGroups.map((group)=>{
            switch(group){
                case EIso3166OneAlpha2CountryGroup.AWS:
                    return Object.values(EIso3166OneAlpha2AWSCountries);
                case EIso3166OneAlpha2CountryGroup.CLOUDFLARE:
                    return Object.values(EIso3166OneAlpha2CloudflareCountries);
                case EIso3166OneAlpha2CountryGroup.EEA:
                    return Object.values(EIso3166OneAlpha2EeaCountries);
                case EIso3166OneAlpha2CountryGroup.EU:
                    return Object.values(EIso3166OneAlpha2EuCountries);
                case EIso3166OneAlpha2CountryGroup.FASTLY:
                    return Object.values(EIso3166OneAlpha2FastlyCountries);
                case EIso3166OneAlpha2CountryGroup.GOOGLECLOUD:
                    return Object.values(EIso3166OneAlpha2GoogleCloudCountries);
                case EIso3166OneAlpha2CountryGroup.AUTOMATTIC:
                    return Object.values(EIso3166OneAlpha2AutomatticCountries);
                default:
                    return [];
            }
        }).flat();
        resolveDataProcessingInCountries = [
            ...new Set([
                ...filteredDataProcessingInCountries,
                ...resolvedCountries
            ])
        ];
    }
    return resolveDataProcessingInCountries;
}
function getGdprCountries() {
    return [
        ...new Set([
            ...Object.values(EIso3166OneAlpha2AdequacyCountries),
            ...Object.values(EIso3166OneAlpha2EeaCountries)
        ])
    ].filter(Boolean).map((country)=>String(country)).sort((a, b)=>String(a).localeCompare(String(b)));
}
export { getGdprCountries, resolveDataProcessingCountries, iso3166OneAlpha2KeyValue };
